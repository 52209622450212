<template>
  <div>
    <h2 class="text-center mt-5 text-danger">На жаль у Вас немає повноважень користуватися даною послугою</h2>
  </div>
</template>

<script>
export default {
  name: 'Error'
}
</script>
